import {
  PlusOutlined,
  UserOutlined,
  LinkOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import AdminMenu from "../Adm_Menu";
import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
  Image,
  Card,
} from "antd";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "moment/locale/th";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
moment.locale("th");

const { Option } = Select;
const { TextArea } = Input;

const AdmInfoEdit = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [form] = Form.useForm();
  const [med, setmed] = useState([]);
  const [user, setUser] = useState([]);
  const [selectednum_mem, setSelectednum_mem] = useState("");
  const [selectOptions_med, setSelectOptions_med] = useState([]);
  const [formattedDate1, setFormattedDate1] = useState(null);
  const [formattedDate2, setFormattedDate2] = useState(null);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };
  useEffect(() => {
    fetchmed();
  }, []);

  const handlenum_memChange = (value) => {
    setSelectednum_mem(value);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const fetchUser = async () => {
    try {
      const response = await fetch(
        "https://checkkonproject-sub.com/api/AmUser"
      );
      if (response.ok) {
        const data = await response.json();
        const options = data.map((code) => (
          <Option key={code[`id`]} value={code[`id`]}>
            <Typography variant="body1" sx={{ fontSize: "20px" }}>
              {code["username"]}
            </Typography>
          </Option>
        ));
        setUser(options);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);
  const fetchmed = async () => {
    try {
      const response = await fetch(
        "https://checkkonproject-sub.com/api/MediaChannels_request"
      );
      if (response.ok) {
        const Data = await response.json();
        setmed(Data);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const fetchFakeNewsData = async () => {
      try {
        const response = await fetch(
          `https://checkkonproject-sub.com/api/FakeNewsInfo_edit/${id}`
        );
        if (response.ok) {
          const FakeNewsData = await response.json();
          setData(FakeNewsData);
          form.setFieldsValue({
            fn_info_nameid: FakeNewsData.fn_info_nameid,
            fn_info_head: FakeNewsData.fn_info_head,
            fn_info_content: FakeNewsData.fn_info_content,
            fn_info_source: FakeNewsData.fn_info_source,
            fn_info_num_mem: FakeNewsData.fn_info_num_mem,
            fn_info_more: FakeNewsData.fn_info_more,
            fn_info_link: FakeNewsData.fn_info_link,
          });
          setFormattedDate1(
            moment(FakeNewsData.fn_info_dmy).format("YYYY-MM-DD")
          );
          setFormattedDate2(
            moment(FakeNewsData.created_at).format("YYYY-MM-DD")
          );
        } else {
          console.error("Invalid date received from the server");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchFakeNewsData();
  }, [id, med, form]);
  const onFinish = async (values) => {
    try {
      const filteredIds = med.filter(
        (item) => item.id === (data && data.fn_info_source)
      );
      const formData = new FormData();
      const appendIfDefined = (fieldName, value) => {
        if (value !== undefined) {
          formData.append(fieldName, value);
        }
      };
      appendIfDefined("created_at", values.created_at);
      appendIfDefined("fn_info_nameid", values.fn_info_nameid);
      appendIfDefined("fn_info_head", values.fn_info_head);
      appendIfDefined("fn_info_content", values.fn_info_content);
      if (values.fn_info_source !== filteredIds[0]?.med_c_name) {
        formData.append("fn_info_source", values.fn_info_source);
      }
      appendIfDefined("fn_info_num_mem", values.fn_info_num_mem);
      appendIfDefined("fn_info_more", values.fn_info_more);
      appendIfDefined("fn_info_link", values.fn_info_link);
      appendIfDefined("fn_info_dmy", values.fn_info_dmy);
      if (values.fn_info_image !== undefined) {
        values.fn_info_image.forEach((file, index) => {
          formData.append(`fn_info_image_${index}`, file.originFileObj);
        });
      }
      const response = await fetch(
        `https://checkkonproject-sub.com/api/FakeNewsInfo_update/${id}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        console.log("Form data updated successfully");
        message.success("Form data updated successfully");
        navigate(`/Admin/ManageInfo/ManageInfo_view/${id}`);
      } else {
        message.error("Error updating form data");
      }
    } catch (error) {
      console.error("Error updating form data:", error);
      message.error("Error updating form data");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const onChange_dnc_med_id = async () => {
      try {
        const response = await fetch(
          "https://checkkonproject-sub.com/api/MediaChannels_request"
        );
        if (response.ok) {
          const typeCodes = await response.json();
          const options = typeCodes.map((code) => (
            <Option key={code[`id`]} value={code[`id`]}>
              <Typography variant="body1" sx={{ fontSize: "20px" }}>
                {code["med_c_name"]}
              </Typography>
            </Option>
          ));
          setSelectOptions_med(options);
        } else {
          console.error(`Error fetching  codes:`, response.statusText);
        }
      } catch (error) {
        console.error(`Error fetching  codes:`, error);
      }
    };
    onChange_dnc_med_id();
  }, []);
  return (
    <AdminMenu>
      <Card className="cardsection">
        <div className="cardsectionContent">แก้ไขรายงานการแจ้งข้อมูลเท็จ</div>
      </Card>
      <br />
      <Card style={{ borderRadius: 25 }}>
        <Form
          form={form}
          layout="vertical"
          name="FakeNewInformation"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            maxWidth: "100%",
            padding: "5%",
          }}
          enctype="multipart/form-data"
        >
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                วัน/เดือน/ปี ที่แจ้งเก่า {formattedDate2} (หากไม่ต้องการเปลี่ยน ไม่ต้องกรอกใหม่)
              </Typography>
            }
            name="created_at"
            rules={[
              {
                required: false,
                message: "กรุณาระบุวัน/เดือน/ปี",
              },
            ]}
          >
            <DatePicker
              style={{
                borderRadius: "25px",
                height: "50px", // Adjust this value for taller input
                padding: "15px", // Optional: to adjust the inner spacing
                backgroundColor: "white", // Set background color to white
                color: "black", // Text color
              }}
              size="large"
              placeholder="วัน/เดือน/ปี"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                ผู้ส่งรายงาน
              </Typography>
            }
            name="fn_info_nameid"
            rules={[
              {
                required: false,
                message: "Please input your email!",
              },
            ]}
          >
            <Select
              style={{
                height: "40px", // Adjust this value for taller input
              }}
              placeholder="Select a option and change input text above"
              allowClear
            >
              {user}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                หัวข้อ
              </Typography>
            }
            name="fn_info_head"
            rules={[
              {
                required: false,
                message: "กรุณาระบุหัวข้อ",
              },
            ]}
          >
            <Input
              style={{
                borderRadius: "25px",
                height: "50px", // Adjust this value for taller input
                padding: "15px", // Optional: to adjust the inner spacing
                backgroundColor: "white", // Set background color to white
                color: "black", // Text color
              }}
              size="large"
              //prefix={<LinkOutlined className="site-form-item-icon" />}
              placeholder="ระบุหัวข้อ"
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                เนื้อหา
              </Typography>
            }
            name="fn_info_content"
            rules={[
              {
                required: false,
                message: "Please input your email!",
              },
            ]}
          >
            <TextArea
              style={{
                borderRadius: "25px",
                padding: "15px", // Optional: to adjust the inner spacing
                backgroundColor: "white", // Set background color to white
                color: "black", // Text color
              }}
              rows={4}
              size="large"
              prefix={<EnvironmentOutlined className="site-form-item-icon" />}
              placeholder="เนื้อหา"
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                แหล่งที่มาของข่าวปลอม
              </Typography>
            }
            name="fn_info_source"
            rules={[
              {
                required: false,
                message: "Please input your email!",
              },
            ]}
          >
            <Select
              style={{
                height: "40px", // Adjust this value for taller input
              }}
              placeholder="Select a option and change input text above"
              allowClear
            >
              {selectOptions_med}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                จำนวนสมาชิกที่อยู่ในกลุ่มที่อาจเผยแพร่ข้อมูลเท็จ
              </Typography>
            }
            name="fn_info_num_mem"
            rules={[
              {
                required: false,
                message: "Please input your email!",
              },
            ]}
          >
            <Select
              style={{
                height: "40px", // Adjust this value for taller input
              }}
              size="large"
              placeholder="จำนวนสมาชิกที่อยู่ในกลุ่มที่อาจเผยแพร่ข้อมูลเท็จ"
              onChange={handlenum_memChange}
              value={selectednum_mem}
            >
              <Select.Option value="น้อยกว่า 50">น้อยกว่า 50</Select.Option>
              <Select.Option value="51-100">51-100</Select.Option>
              <Select.Option value="101-150">101-150</Select.Option>
              <Select.Option value="151-200">151-200</Select.Option>
              <Select.Option value="201-250">201-250</Select.Option>
              <Select.Option value="251-300">251-300</Select.Option>
              <Select.Option value="301-350">301-350</Select.Option>
              <Select.Option value="351-400">351-400</Select.Option>
              <Select.Option value="401-450">401-450</Select.Option>
              <Select.Option value="451-500">451-500</Select.Option>
              <Select.Option value="มากกว่า 501">มากกว่า 501</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                รายละเอียดเพิ่มเติม
              </Typography>
            }
            name="fn_info_more"
            rules={[
              {
                required: false,
                message: "กรุณากรอกรายละเอียดเพิ่มเติม",
              },
            ]}
          >
            <TextArea
              style={{
                borderRadius: "25px",
                padding: "15px", // Optional: to adjust the inner spacing
                backgroundColor: "white", // Set background color to white
                color: "black", // Text color
              }}
              rows={4}
              size="large"
              prefix={<EnvironmentOutlined className="site-form-item-icon" />}
              placeholder="รายละเอียดเพิ่มเติม"
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                ระบุลิ้งค์ข้อมูล(ถ้ามี)
              </Typography>
            }
            name="fn_info_link"
            rules={[
              {
                required: false,
                message: "กรุณาระบุลิ้งค์ข้อมูล(ถ้ามี)",
              },
            ]}
          >
            <Input
              style={{
                borderRadius: "25px",
                height: "50px", // Adjust this value for taller input
                padding: "15px", // Optional: to adjust the inner spacing
                backgroundColor: "white", // Set background color to white
                color: "black", // Text color
              }}
              size="large"
              prefix={<LinkOutlined className="site-form-item-icon" />}
              placeholder="ระบุลิ้งค์ข้อมูล(ถ้ามี)"
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                วัน/เดือน/ปี ที่เกิดเหตุเก่า {formattedDate1} (หากไม่ต้องการเปลี่ยน ไม่ต้องกรอกใหม่)
              </Typography>
            }
            name="fn_info_dmy"
            rules={[
              {
                required: false,
                message: "กรุณาระบุวัน/เดือน/ปี",
              },
            ]}
          >
            <DatePicker
              style={{
                borderRadius: "25px",
                height: "50px", // Adjust this value for taller input
                padding: "15px", // Optional: to adjust the inner spacing
                backgroundColor: "white", // Set background color to white
                color: "black", // Text color
              }}
              size="large"
              placeholder="วัน/เดือน/ปี"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                ส่งภาพบันทึกหน้าจอหรือภาพถ่ายที่พบข้อมูลเท็จใหม่
              </Typography>
            }
            name="fn_info_image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: false,
                message:
                  "กรุณาแนบภาพบันทึกหน้าจอหรือภาพถ่ายที่พบข้อมูลเท็จ (ให้ Upload ใหม่ทุกครั้งเมื่อทำการแก้ไข)",
              },
            ]}
          >
            <Upload
              name="fn_info_image"
              maxCount={3}
              listType="picture-card"
              beforeUpload={() => false}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Typography variant="body1" sx={{ fontSize: "25px" }}>
            ภาพบันทึกหน้าจอหรือภาพถ่ายที่พบข้อมูลเท็จเก่า
          </Typography>
          <br />
          {data && (
            <>
              {[
                data.fn_info_image_0,
                data.fn_info_image_1,
                data.fn_info_image_2,
              ]
                .filter((image) => image !== null)
                .map((image, index) => (
                  <Image
                    key={`image_${index}`}
                    style={{
                      width: "200px",
                      height: "200px",
                      marginRight: "10px",
                    }}
                    src={image}
                    alt={`รูปภาพข่าวปลอม ${index}`}
                  />
                ))}
            </>
          )}
          <br />
          <br />
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="form-button"
              size="large"
            >
              <Typography variant="body1" sx={{ fontSize: "25px" }}>
                ยืนยัน
              </Typography>
            </Button>
          </Form.Item>
        </Form>
        <Button
          danger
          shape="round"
          type="primary"
          className="form-button-cancel"
          size="large"
          onClick={handleCancel}
        >
          <Typography variant="body1" sx={{ fontSize: "25px" }}>
            ยกเลิก
          </Typography>
        </Button>
      </Card>
    </AdminMenu>
  );
};

export default AdmInfoEdit;
