import React from "react";
import AppRoutes from "./AppRoutes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { PrimeReactProvider } from 'primereact/api';

const App = () => {
  return (
    <PrimeReactProvider>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </PrimeReactProvider>
  );
};

export default App;
