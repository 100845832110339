import React, { useEffect, useState } from "react";
import { Layout, Space } from "antd";
import CASLogo from "./Images/CAS.png";
import WMOLogo from "./Images/WMO.png";
import { Grid } from "@mui/material";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom"; // Use Link from react-router-dom for internal navigation

const { Footer } = Layout;

const Bottom = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the correct value
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Footer className="footer">
      <br />
      <Grid container spacing={2}>
        {/* Left Section: Logo and Project Info */}
        <Grid item xs={12} md={7}>
          <img
            src="https://www.commsci.psu.ac.th/wp-content/uploads/2023/09/logo-web-V2.0.svg"
            alt="WMO Logo"
            className="commsci-image"
          />
          <div className="text">
            สร้างสรรค์โดย โครงการวิจัย เรื่อง
            การศึกษาและสร้างสรรค์สื่อเพื่อเฝ้าระวังข้อมูลผิดพลาดสำหรับเครือข่ายผู้บริโภคภาคใต้
          </div>
          <div className="text">
            (The Study and Media Creation to Misinformation Surveillance for
            Southern Consumer Network)
          </div>
          <div className="text">
            ภายใต้การสนับสนุนของกองทุนวิจัย คณะวิทยาการสื่อสาร
            มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตปัตตานี
          </div>
          <div>
            <Space align="center">
              <span>
                <img src={WMOLogo} alt="WMO Logo" className="partner-image" />
                <div className="text" style={{ textAlign: "left", marginTop: "auto" }}>
                  <strong style={{ color: "#208b99" }}>
                    เครือข่ายเฝ้าระวังสื่อออนไลน์ภาคใต้
                  </strong>
                </div>
              </span>
              {!isMobile && (
                <span style={{ margin: "0 20px" }}>------------ -----------</span>
              )}
              <span>
                <img src={CASLogo} alt="CAS Logo" className="partner-image" />
                <div className="text" style={{ textAlign: "left", marginTop: "auto" }}>
                  <strong style={{ color: "#208b99" }}>
                    สมาคมผู้บริโภคสงขลา
                  </strong>
                </div>
              </span>
            </Space>
          </div>
        </Grid>

        {/* Middle Section: Navigation Menu */}
        <Grid item xs={12} md={2}>
          <div className="text" style={{ textAlign: "left", marginTop: "auto" }}>
            <strong style={{ color: "#208b99" }}>เมนู</strong>
            <br />
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>หน้าหลัก</Link>
            <br />
            <Link to="/News_Menu" style={{ color: "inherit", textDecoration: "none" }}>ข่าวสาร</Link>
            <br />
            <Link to="/Article_Menu" style={{ color: "inherit", textDecoration: "none" }}>บทความ</Link>
            <br />
            <Link to="/MediaShare_Menu" style={{ color: "inherit", textDecoration: "none" }}>สื่อชวนแชร์</Link>
            <br />
            <Link to="/FakeNews_Menu" style={{ color: "inherit", textDecoration: "none" }}>แจ้งข่าวปลอม</Link>
            <br />
            <Link to="/About_Us_View" style={{ color: "inherit", textDecoration: "none" }}>เกี่ยวกับเรา</Link>
          </div>
        </Grid>

        {/* Right Section: Contact Information */}
        <Grid item xs={12} md={3}>
          <div className="text" style={{ textAlign: "left", marginTop: "auto" }}>
            <strong style={{ color: "#208b99" }}>ติดต่อโครงการวิจัย</strong>
            <br />
            คณะวิทยาการสื่อสาร มหาวิทยาลัยสงขลานครินทร์
            <br />
            วิทยาเขตปัตตานี อำเภอเมือง จังหวัดปัตตานี 94000
            <br />
            <PhoneOutlined /> โทร. 073 349 692
            <br />
            <MailOutlined /> Email: checkkonproject@gmail.com
          </div>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default Bottom;
